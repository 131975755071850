/*
 * @Author: biqianqian
 * @Date: 2022-12-19 17:09:33
 * @LastEditors: biqianqian
 * @LastEditTime: 2023-01-09 14:10:57
 * @filePath: Do not edit
 */
// 路由配置表
// import Components from './comonents'
import { asyncRouterMap } from '../router'
export default (routers, data) => {
  generaRouter(routers,data)
}
let icon = ['el-icon-s-platform','el-icon-s-order']

function generaRouter(routers,data){
  // console.log(asyncRouterMap)
  let arr = asyncRouterMap
  arr[0].children.splice(10,arr[0].children.length)
  if(data && data.length > 0) {
    data.forEach((item) => {
      // let menu = Object.assign({}, item)
      if (item.pid == 0) {
        item.component = () => import('@/components/emptyView')
        let title = {
            title: item.menuName
        }
        item.meta = title
      }
      let parentPath = item.path.split('/')[1]
      if (item.path == '/count') {
          item.icon = icon[0]
      } else if(item.path == '/manage') {
          item.icon = icon[1]
      }
            
            if(item.children) {
                let children = item.children.sort(compare(item.children))
                children.forEach(elem => {
                    let path = elem.path.split("/")[1]
                    elem.name = path
                    elem.component = () => import(`@/components/${parentPath}/${path}`)
                    let title = {
                        title: elem.menuName
                    }
                    elem.meta = title
                })
            }

     let isHave = asyncRouterMap[0].children.filter(v => v.id == item.id)
     if (isHave.length == 0) {
        arr[0].children.push(item)
     }
     
    })
    routers.push(arr[0])
  }
//routers.push(data[0])
}
function compare() {
  return function(a,b) {
    let val1 = a['sort']
    let val2 = b['sort']
    return val1 - val2
  }
}