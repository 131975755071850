import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
  
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/loginView')
  }
  
]
 
// export const record = [
//   {
//     path: '/createRecord',
//     name: 'createRecord',
//     hidden: true,
//     meta: {
//     title: '建档'
//     },
//     component: () => import('@/components/record/createRecord')
//   },
//   {
//     path: '/physicalList',
//     name: 'physicalList',
//     hidden: true,
//     meta: {
//     title: '建档'
//     },
//     component: () => import('@/components/record/physicalList')
//   },
//   {
//     path: '/physicalTable',
//     name: 'physicalTable',
//     hidden: true,
//     meta: {
//     title: '个人信息'
//     },
//     component: () => import('@/components/record/physicalTable')
//   },
//   {
//     path: '/personInfo',
//     name: 'personInfo',
//     hidden: true,
//     meta: {
//     title: '健康体检'
//     },
//     component: () => import('@/components/record/personInfo')
//   }
// ]
  
 
export default new Router({
  routes: constantRouterMap
})

export const asyncRouterMap = [
  {
    path: '/layout',
    component: () => import('@/layout/homeView'),
    children: [
      // {
      //   path: '/inocuSure',
      //   name: 'inocuSure',
      //   hidden: true,
      //   meta: {
      //   title: '接种确认'
      //   },iew
      //   component: () => import('@/components/user/inocuSure')
      // },
      // {
      //     path: '/appointSure',
      //     name: 'appointSure',
      //     hidden: true,
      //     meta: {
      //     title: '预约时间'
      //     },
      //     component: () => import('@/components/user/appointSure')
      // },
      {
          path: '/addMember',
          name: 'addMember',
          hidden: true,
          meta: {
          title: '编辑用户'
          },
          component: () => import('@/components/manage/addMember')
      },
      
      // {
      //     path: '/addMenu',
      //     name: 'addMenu',
      //     hidden: true,
      //     meta: {
      //     title: '编辑菜单'
      //     },
      //     component: () => import('@/components/manage/addMenu')
      // },
      // {
      //     path: '/addRole',
      //     name: 'addRole',
      //     hidden: true,
      //     meta: {
      //     title: '编辑角色'
      //     },
      //     component: () => import('@/components/manage/addRole')
      // },
      // {
      //     path: '/addDept',
      //     name: 'addDept',
      //     hidden: true,
      //     meta: {
      //     title: '编辑机构'
      //     },
      //     component: () => import('@/components/manage/addDept')
      // },
      {
          path: '/addNews',
          name: 'addNews',
          hidden: true,
          meta: {
          title: '社区动态编辑'
          },
          component: () => import('@/components/manage/addNews')
      },
      // {
      //     path: '/addChild',
      //     name: 'addChild',
      //     hidden: true,
      //     meta: {
      //     title: '社区服务编辑'
      //     },
      //     component: () => import('@/components/manage/addService')
      // },
      {
          path: '/addFamily',
          name: 'addFamily',
          hidden: true,
          meta: {
          title: '社区服务编辑'
          },
          component: () => import('@/components/manage/addFamily')
      },
      {
          path: '/deptSelect',
          name: 'deptSelect',
          hidden: true,
          meta: {
          title: '编辑疫苗'
          },
          component: () => import('@/components/manage/deptSelect')
      }
    ]
  }
]

