// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import scroll from 'vue-seamless-scroll'
import "@/utils/filters"; //全局vue过滤器

Vue.use(ElementUI )
Vue.use(scroll)
Vue.config.productionTip = false


if (localStorage.getItem('token') && localStorage.getItem('routes')) {
  let path = JSON.parse(localStorage.getItem('routes'));
  store.dispatch("add_Routes", path)
}

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');
  if(!token && to.path !== '/login') {
    next({name: 'login'});
  } else if (to.path == '/'){
    next({name: 'login'});
  } else {
    next()
  }
});



/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

